<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <to-auth-button/>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <form @submit.prevent="handleLogin">
        <ion-item lines="full">
          <ion-label position="floating">Nome de usuario</ion-label>
          <ion-input @input="user.username=$event.target.value" type="text" required></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="floating">Contrasinal</ion-label>
          <ion-input
             @input="user.password=$event.target.value"
            type="password"
            required
          ></ion-input>
        </ion-item>

        <ion-row>
          <ion-col>
            <ion-button type="submit" color="danger" expand="block"
              >Iniciar sesión</ion-button
            >
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import User from "../models/user";
import ToAuthButton from "@/components/ToAuthButton.vue"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";

export default {
  name: "Login",
  components: {
    IonHeader,
    ToAuthButton,
    IonToolbar,
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonButton,
  },
  data() {
    return {
      user: new User("", "", ""),
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/materiais');
    }
  },
  methods: {
    handleLogin() {
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/materiais");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>